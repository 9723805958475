/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


.capitalize {
    text-transform: capitalize;
}

.uppercase {
    text-transform: uppercase;
}
.lowcase {
    text-transform: lowercase;
}

.center {
    text-align: center;
}
.left {
    text-align: left;
}
.right {
    text-align: right;
}
.bold {
    font-weight: bold;
}

.justify {
    text-align:justify;
}
.fas,
.far,
.fal,
.fad {       
    //text-align:center;
    zoom: 1.5;
    //background-color:red;
    
}

.block {
    display:block;
}

.ql-align-center{
    text-align: center !important;
}
.ql-align-right{
    text-align: right !important;
}
.ql-align-justify{
    text-align: justify !important;
}

.content {
    display:block;
    padding: 0 6%;
    --padding-start: 6%;
    --padding-end: 6%;
}

.link{
    cursor: pointer;
}

.title-head {
    display:block;
    font-size:2.5em;
    font-weight: bold;
    color:var(--ion-color-tertiary);
    padding: 0.5em 1.5em 0em 1.5em !important;
    margin: 0.5em 1em 0.5em 1em !important;
    font-family: "Papyrus", 'Open Sans', 'Helvetica Neue', sans-serif;
    z-index:90;
    
}